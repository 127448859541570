// Angular modules
import { CurrencyPipe, IMAGE_LOADER } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

// Environment variables
import { environment } from '@cosCoreEnvironments/environment';
import { AppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';

// Vendor modules
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCcStripe, faSalesforce, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faFileAlt as farFileAlt, faStickyNote as farStickyNote } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ColorPickerModule } from 'ngx-color-picker';
import { CountdownModule } from 'ngx-countdown';
import { NgxPrintModule } from 'ngx-print';
import { RecaptchaModule } from 'ng-recaptcha';
import * as Sentry from '@sentry/angular';

// Translation & locales
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { COS_CONSTANTS, CosI18nService, CountryCodeService } from '@caronsale/frontend-services';
import { CosLocales } from '@caronsale/frontend-utils';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';

// App services
import { CosTransportationProviderClientService } from '@cosTransportServices/cos-transportation-provider-client/cos-transportation-provider-client.service';
import { DateUtilsService } from '@cosTransportServices/date-utils-service/date-utils-service';
import { TransportationTasksManagementService } from '@cosTransportServices/transportation-tasks-management-service/transportation-tasks-management-service';
import { CosSellerClientService } from '@cosCoreServices/cos-seller-client/cos-seller-client.service';
import { CosPublicClientService } from '@cosCoreServices/cos-public-client/cos-public-client.service';
import { CosInternalClientService } from '@cosCoreServices/cos-internal-client/cos-internal-client.service';
import { InternalBuyerWhatsappClientService } from '@cosCRMComponents/internal-salesman-whatsapp-client/internal-salesman-whatsapp-client.service';
import { I18nValidationService } from '@cosCoreServices/validation-service/i18n-validation.service';
import { I18nSnackService } from '@cosCoreServices/i18n-snack/i18n-snack.service';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { ConfigService } from '@cosCoreServices/config/config.service';

// App modules
import { AppComponent } from './app.component';
import { AppCRMMaterialModule } from './modules/app-material.module';
import { AppCRMRoutingModule } from './modules/app-crm-routing.module';
import { FormValidationModule } from '@cosCoreModules/form-validation/form-validation.module';
import { CookieLawComponent } from '@cosCoreComponentsGeneral/cookie-law/cookie-law.component';
import { CosPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/cos-popover.module';
import { TransportComponentsModule } from './modules/transport-components.module';
import { TransportationOnlyPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/components/transportation-only-popover/transportation-only-popover.module';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxCustomDateAdapter } from '@cosCoreConfig/custom-date-adapter';
import { InternalUserEmailSettingsModule } from '@cosCRMComponents/internal-user-email-settings/internal-user-email-settings.module';
import { BuyerGuaranteeOptionsDialogComponent } from '@cosCoreFeatures/buyer/components/buyer-guarantee-options-dialog/buyer-guarantee-options-dialog.component';
import { BuyerGuaranteeAdvertiseDialogModule } from '@cosCoreFeatures/buyer/components/buyer-guarantee-advertise-dialog/buyer-guarantee-advertise-dialog.module';
import {
  MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS as MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatLegacyCheckboxDefaultOptions as MatCheckboxDefaultOptions,
} from '@angular/material/legacy-checkbox';
import { NgxMaskModule } from 'ngx-mask';
import { BiddingConfirmationDialogComponent } from '@cosBuyer/partials/services/bidding/bidding-confirmation-dialog/bidding-confirmation-dialog.component';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { customCloudinaryLoader } from '@cosCoreUtils/cloudinary-loader';
import { ToastrModule } from 'ngx-toastr';
import { I18nErrorDialogComponent } from '@cos/components/general/i18n/error-dialog/i18n-error-dialog.component';
import { SentryGlobalErrorHandler } from '@cosCoreServices/global-error-handler/sentry-global-error-handler.service';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { PhraseTranslateLoader } from '@cosCoreUtils/translate/phrase-translate-loader';
import { CosMissingTranslationHandler } from '@cosCoreUtils/translate/cos-missing-translations-handler';
import { I18nService } from '@cosCoreServices/i18n/i18n.service';

CosLocales.registerLocales();

const appInitializerFn = (appConfig: ConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularEditorModule,
    AppCRMMaterialModule,
    AppCRMRoutingModule,
    BiddingConfirmationDialogComponent,
    BrowserModule,
    BrowserAnimationsModule,
    BuyerGuaranteeAdvertiseDialogModule,
    BuyerGuaranteeOptionsDialogComponent,
    ColorPickerModule,
    CookieLawComponent,
    CosPopoverModule,
    CountdownModule,
    EnzoComponentsModule,
    FlexModule,
    FontAwesomeModule,
    FormsModule,
    FormValidationModule,
    HttpClientModule,
    I18nErrorDialogComponent,
    InternalUserEmailSettingsModule,
    NgxChartsModule,
    NgxMaskModule.forRoot(),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxPrintModule,
    ReactiveFormsModule,
    RecaptchaModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      useDefaultLang: true,
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new PhraseTranslateLoader(http, environment.phraseConfig),
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CosMissingTranslationHandler,
      },
    }),
    TransportationOnlyPopoverModule,
    TransportComponentsModule,
    environment.envModules,
  ],
  providers: [
    COS_CONSTANTS,
    CountryCodeService,
    // ToDo: Update dependency injections to use I18Service directly and delete this "alias" (I8nService is provided in root)
    {
      provide: CosI18nService,
      useClass: I18nService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigService],
    },
    HttpClient,
    CosPublicClientService,
    DateUtilsService,
    CosInternalClientService,
    CosTransportationProviderClientService,
    TransportationTasksManagementService,
    {
      provide: AppEnvironment,
      useValue: environment,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de',
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.googleTagManagerId,
    },
    {
      provide: 'googleAnalyticsId',
      useValue: environment.googleAnalyticsId,
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { color: 'primary' } as MatCheckboxDefaultOptions,
    },
    { provide: NgxMatDateAdapter, useClass: NgxCustomDateAdapter },
    CosSellerClientService,
    CosBuyerClientService, // auction-bidding service requires it and it is not "provided in root"
    InternalBuyerWhatsappClientService,
    I18nValidationService,
    I18nSnackService,
    CurrencyPipe,
    { provide: IMAGE_LOADER, useValue: customCloudinaryLoader },
    {
      provide: ErrorHandler,
      useFactory: (cosCoreClient, productAnalyticsService) => new SentryGlobalErrorHandler(cosCoreClient, productAnalyticsService),
      deps: [CosCoreClient, ProductAnalyticsService],
    },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor(
    library: FaIconLibrary,
    matIconRegistry: MatIconRegistry,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    productAnalyticsService: ProductAnalyticsService, // must be injected on init to prevent the creation of anonymous users
  ) {
    library.addIconPacks(fas);
    library.addIcons(farFileAlt);
    library.addIcons(farStickyNote, faCcStripe, faSalesforce, faWhatsapp);
    matIconRegistry.setDefaultFontSetClass('fas');
  }
}
