import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { privilegesGuard } from '@cosCoreGuards/privileges.guard';
import { USER_PRIVILEGES } from '@caronsale/cos-models';
import { authGuard } from '@cosCoreGuards/auth.guard';

const appCRMRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('@cosCRMComponents/internal-login/internal-login.module').then(m => m.InternalLoginModule),
  },
  {
    path: 'login',
    loadChildren: () => import('@cosCRMComponents/internal-login/internal-login.module').then(m => m.InternalLoginModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('@cosCRMComponentsAdmin/admin-view-container/admin-view-container.module').then(m => m.AdminViewContainerModule),
    canActivate: [privilegesGuard(USER_PRIVILEGES.SYSADMIN_USER)],
  },
  {
    path: 'internal/kam',
    loadChildren: () => import('@cosCRMComponentsInternal/kam-view-container/internal-kam-view-container.module').then(m => m.InternalKamViewContainerModule),
    canActivate: [authGuard()],
  },
  {
    path: '**',
    loadChildren: () => import('@cosCRMComponentsGeneral/not-found/not-found.module').then(m => m.CRMNotFoundModule),
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appCRMRoutes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppCRMRoutingModule {}
